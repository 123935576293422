// @flow
import * as React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import compact from 'lodash/compact';
import classnames from 'classnames';

import TitleAndDescription from '~components/TitleAndDescription';
import SliceInnerContainer from '~components/SliceInnerContainer';
import withBodySectionSlice from '~plugins/material-ui/hocs/withBodySectionSlice';
import hasRichTextFieldValue from '~plugins/prismic/helpers/hasRichTextFieldValue';
import getTravelType from '~helpers/getTravelType';

import type { Props } from './types';
import styles from './styles';
import TravelGridItemSlice from './TravelGridItemSlice';

const TravelGridSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || 'div';
  const lineItems = data.primary?.items_per_row;
  const gridItemProps = {
    '1': { xs: 12 },
    '2': { xs: 12, sm: 6 },
    '3': { xs: 12, sm: 6, md: 4 },
    '4': { xs: 12, sm: 6, md: 3 },
  }[lineItems || '1'];

  const renderedItems = React.useMemo(
    () =>
      compact(
        map(data.items, (item, index: number) => {
          const travel = getTravelType(item?.item_link);
          return travel ? (
            <Grid
              key={index}
              item
              {...gridItemProps}
              className={classes.itemsGridItem}
            >
              <TravelGridItemSlice
                node={travel}
                travelTitle={travel?.data?.title}
                travelSubtitle={travel?.data?.subtitle}
                travelBgImage={travel?.data?.background_image}
                travelTag={travel?.data?.travel_tag}
                classes={{
                  root: classes.itemWrapper,
                  content: classes.itemContent,
                  title: classes.itemContentTitle,
                  hiddenContent: classes.itemHiddenContent,
                }}
              />
            </Grid>
          ) : null;
        }),
      ),
    [data.items],
  );

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      {hasRichTextFieldValue(data?.primary?.slice_title) ||
      hasRichTextFieldValue(data?.primary?.slice_description) ? (
        <Container className={classes.titleContainer}>
          <TitleAndDescription
            title={data?.primary?.slice_title}
            description={data?.primary?.slice_description}
            backgroundColor={data?.primary?.slice_background_color}
          />
        </Container>
      ) : null}
      <SliceInnerContainer data={data}>
        <Grid container spacing={1} className={classes.itemsGridContainer}>
          {renderedItems}
        </Grid>
      </SliceInnerContainer>
    </Component>
  );
};

TravelGridSlice.defaultProps = {
  className: undefined,
};

export const StyledTravelGridSlice = withStyles<*, *, Props>(styles)(
  TravelGridSlice,
);

export default withBodySectionSlice<
  React.ElementConfig<typeof StyledTravelGridSlice>,
>({ Container: null })(StyledTravelGridSlice);
