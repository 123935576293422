// @flow
import * as React from 'react';

import Link from '../../../i18n/components/Link';
import useNodePath, {
  type Config as UseNodePathConfig,
} from '../../hooks/useNodePath';

type LinkProps = React.ElementConfig<typeof Link>;

export type Props = {
  ...$Exact<$Diff<LinkProps, { to: any, localized: any }>>,
  ...$Exact<UseNodePathConfig>,
};

export type State = {
  error: ?Error,
};

const NodeLinkInner = ({ node, ...props }: Props) => (
  <Link {...props} localized={false} to={useNodePath({ node }) ?? '#'} />
);

export default NodeLinkInner;
