// @flow
import type { Styles } from './types';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {},
  titleContainer: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          paddingBottom: theme.spacing(getBreakPointFactor(breakpoint) * 2),
        },
      }),
      {},
    ),
  },
  itemsGridContainer: {},
  itemsGridItem: {},
  itemWrapper: {
    [theme.breakpoints.up('md')]: {
      minHeight: ({ data }) =>
        data?.primary?.items_per_row === '1' ? 450 : undefined,
    },
  },
  itemContent: {},
  itemContentTitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: ({ data }) => {
        switch (data?.primary?.items_per_row) {
          case '1': {
            return theme.typography.h2.fontSize;
          }
          case '2': {
            return theme.typography.h3.fontSize;
          }
          default: {
            return theme.typography.h4.fontSize;
          }
        }
      },
    },
  },
  itemHiddenContent:{
    [theme.breakpoints.up('md')]: {
      '& p': {
        fontSize: ({ data }) =>
          data?.primary.items_per_row === '1'
            ? theme.typography.h6.fontSize
            : 'initial',
      },
    },
  }
});

export default styles;
