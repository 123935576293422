// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import classnames from 'classnames';

import RichTextTypography from '~plugins/prismic/components/RichTextTypography';
import NodeImage from '~plugins/prismic/components/NodeImage';
import NodeLink from '~plugins/prismic/components/NodeLink';
import messages from '../../../messages';
import type {
  PrismicStructuredTextType,
  PrismicImageType,
  PrismicTravel,
} from '~schema';

export type ClassKey =
  | 'root'
  | 'backgroundImage'
  | 'content'
  | 'title'
  | 'hiddenContent'
  | 'subtitle'
  | 'link'
  | 'tag'
  | 'emptyContentStart';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  travelTitle: ?PrismicStructuredTextType,
  travelSubtitle: ?PrismicStructuredTextType,
  travelBgImage: ?PrismicImageType,
  travelTag?: ?PrismicStructuredTextType,
  node: ?PrismicTravel,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: 300,
    overflow: 'hidden',
    padding: theme.spacing(3),
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover $hiddenContent': {
      opacity: 1,
      height: 'auto',
      // It will never get this height, just for the animation to work
      maxHeight: '200px',
      flex: 1000,
      transform: 'translateY(0%)',
      transition: theme.transitions.create(['all'], {
        duration: theme.transitions.duration.complex,
      }),
    },
    '&:hover $backgroundImage:after': {
      background: 'rgba(0,0,0,.45)',
    },
    '&:hover $title': {
      flex: '0 0 auto',
    },
  },
  backgroundImage: {
    position: 'absolute !important',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    pointerEvents: 'none',
    '&:after': {
      content: JSON.stringify(''),
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0,0,0,.15)',
      transition: theme.transitions.create(['background']),
    },
  },
  content: {
    zIndex: 1,
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  title: {
    zIndex: 1,
    fontFamily: theme.typography.h4.fontFamily,
    textShadow: '4px 4px 15px rgba(0,0,0,.3)',
    flex: '1 0 auto',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  subtitle: {
    paddingTop: theme.spacing(1),
    textShadow: '4px 4px 15px rgba(0,0,0,.3)',
  },
  link: {
    marginTop: theme.spacing(3),
  },
  tag: {
    zIndex: 1,
    fontSize: '0.6875rem',
  },
  hiddenContent: {
    opacity: 0,
    height: 0,
    maxHeight: 0,
    flex: 0,
    transform: 'translateY(100%)',
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  emptyContentStart: {
    flex: '1000 0 auto',
    transition: theme.transitions.create(['flex'], {
      duration: theme.transitions.duration.complex,
    }),
  },
});

const ContentGridItemSlice = ({
  node,
  travelTitle,
  travelSubtitle,
  travelBgImage,
  travelTag,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const intl = useIntl();

  return (
    <Paper className={classnames(className, classes.root)} {...props}>
      <NodeImage data={travelBgImage} className={classes.backgroundImage} />
      <div className={classes.content}>
        <div className={classes.emptyContentStart} />
        <RichTextTypography
          variant="h4"
          {...travelTitle}
          className={classes.title}
        />
        <Typography variant="overline" className={classes.tag}>
          {travelTag?.text}
        </Typography>
        <div className={classes.hiddenContent}>
          {travelSubtitle ? (
            <Typography className={classes.subtitle}>
              {travelSubtitle?.text}
            </Typography>
          ) : null}
          <Button
            component={NodeLink}
            node={node}
            disableTouchRipple
            variant="contained"
            aria-label={travelTitle?.text}
            className={classes.link}
          >
            {intl.formatMessage(messages.readMore)}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

ContentGridItemSlice.defaultProps = {
  className: undefined,
  travelTag: undefined,
};

export default withStyles<*, *, Props>(styles)(ContentGridItemSlice);
