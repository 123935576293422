// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';

import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import NodeBody from '~plugins/prismic/components/NodeBody';
import HeaderSlice from '~components/slices/HeaderSlice';
import PlainTextSlice from '~components/slices/PlainTextSlice';
import TravelGridSlice from '~components/slices/TravelGridSlice';
import ContentGridSlice from '~components/slices/ContentGridSlice';
import MediaGridSlice from '~components/slices/MediaGridSlice';
import type { PrismicTravelPage } from '~schema';

export type ClassKey = 'root' | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicTravelPage>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginTop: 51,
    [theme.breakpoints.up('lg')]: {
      marginTop: 89,
    },
  },
  bodySection: {},
});

const nodeBodyComponents = {
  header: HeaderSlice,
  plain_text: PlainTextSlice,
  travels_grid: TravelGridSlice,
  content_grid: ContentGridSlice,
  media_grid: MediaGridSlice,
};

const TravelPage = ({ classes, node }: Props) => {
  return (
    <div className={classes.root}>
      <NodeBody
        node={node}
        components={nodeBodyComponents}
        className={classes.bodySection}
      />
    </div>
  );
};

export default compose(
  withNodePage<PrismicTravelPage, *>({
    getNode: data => data?.prismicTravelPage,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(TravelPage);

export const query = graphql`
  query TravelPageQuery($prismicId: ID) {
    prismicTravelPage(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        body {
          ... on Node {
            id
          }
          ... on PrismicSliceType {
            slice_type
          }
          ... on PrismicTravelPageBodyHeader {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_height
            }
          }
          ... on PrismicTravelPageBodyPlainText {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_text {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_text_alignment
              slice_text_justification
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicTravelPageBodyTravelsGrid {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_width
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              items_per_row
            }
            items {
              item_link {
                id
                type
                link_type
                url
                document {
                  ... on PrismicTravel {
                    id
                    uid
                    type
                    lang
                    data {
                      title {
                        text
                        html
                      }
                      subtitle {
                        text
                        html
                      }
                      travel_tag {
                        text
                        html
                      }
                      background_image {
                        alt
                        url
                        fluid(maxWidth: 400) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTravelPageBodyContentGrid {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_width
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              items_per_row
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              item_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicTravelPageBodyMediaGrid {
            id
            slice_type
            primary {
              slice_caption {
                text
                html
              }
              slice_width
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
            items {
              item_media_width
              item_products_list {
                document {
                  ... on PrismicProductList {
                    id
                    data {
                      name {
                        text
                        html
                      }
                      products {
                        product
                      }
                    }
                  }
                }
              }
              item_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_video {
                link_type
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;
